/**
 * Javascript pour Heli-Express
 */

//Correction du code
"use strict";

/* eslint-disable no-useless-escape */
const regexCourriel = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexTel = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

// Définis ici pour éviter de faire trop de getDocumentById()
let champNom = null;
let champTel = null;
let champCourriel = null;
let champMessage = null;
let divErreurNom = null;
let divErreurMessage = null;

let nomValide = false;
let telValide = false;
let courrielValide = false;
let messageValide = false;

/**
 * Appelée lorsqu'il y a un changement dans le nom
 */
function changementDansNom() {
    // Enlève les espaces au début et à la fin :
    champNom.value = champNom.value.trim();
    let msg = "";

    nomValide = champNom.value.length >= 3;
    if (!nomValide) {
        msg = "Doit avoir au moins 3 caractères";
    }
    else {
        const premiereLettre = champNom.value[0];
        nomValide = premiereLettre === premiereLettre.toUpperCase();
        if (!nomValide) {
            // Vérifier que la première lettre est une majuscule.
            // (Pourrait être fait par une regex)
            const premiereLettre = champNom.value[0];
            if (premiereLettre !== premiereLettre.toUpperCase()) {
                msg = "Doit commencer par une majuscule";
            }
        }
    }

    if (msg === "") {
        champNom.classList.add("is-valid");
        champNom.classList.remove("is-invalid");
    } else {
        champNom.classList.remove("is-valid");
        champNom.classList.add("is-invalid");
    }

    divErreurNom.textContent = msg;
}

/**
 * Appelée lorsqu'il y a un changement dans le numéro de téléphone
 */
function changementDansTel() {
    // Enlève les espaces au début et à la fin :
    champTel.value = champTel.value.trim();

    telValide = regexTel.test(champTel.value);
    if (!telValide) {
        champTel.classList.add("is-invalid");
        champTel.classList.remove("is-valid");
      
    } else {
        champTel.classList.add("is-valid");
        champTel.classList.remove("is-invalid");
    }
}

/**
 * Appelée lorsqu'il y a un changement dans le courriel
 */
function changementDansCourriel() {
    // Enlève les espaces au début et à la fin :
    champCourriel.value = champCourriel.value.trim();
    courrielValide = regexCourriel.test(champCourriel.value) && champCourriel.value.length >= 3;
    if (!courrielValide) {
        champCourriel.classList.add("is-invalid");
        champCourriel.classList.remove("is-valid");
    } else {
        champCourriel.classList.add("is-valid");
        champCourriel.classList.remove("is-invalid");
    }
}

/**
 * Appelée lorsqu'il y a un changement dans le message
 */
function changementDansMessage() {
    // Enlève les espaces au début et à la fin :
    champMessage.value = champMessage.value.trim();
    messageValide = champMessage.value.length >= 5;
    let msg = "";
    if (!messageValide) {
        msg = "Doit avoir au moins 5 caractères";
        champMessage.classList.add("is-invalid");
        champMessage.classList.remove("is-valid");
    }
    else {
        champMessage.classList.add("is-valid");
        champMessage.classList.remove("is-invalid");
    }

    divErreurMessage.textContent = msg;
}

/**
 * Gère l'événement Submit
 * @param evenement Événement déclencheur
 */
function gererSubmit(evenement) {
    let tousLesChampsSontValides = false;

    // S'assurer que tous les champs sont valides.
    if (nomValide === true && telValide === true && courrielValide === true && messageValide === true) {
        tousLesChampsSontValides = true;
        return true;
    }
    //erreur si les champs sont vides
    else if (tousLesChampsSontValides === false) {
        // Pour empêcher la soumission :
        evenement.preventDefault();

        changementDansNom();
        changementDansTel();
        changementDansCourriel();
        changementDansMessage();

        return false;
    }
}

/**
 * Gère le reset
 */
function gererReset() {
    champNom.classList.remove("is-valid");
    champNom.classList.remove("is-invalid");
    champTel.classList.remove("is-valid");
    champTel.classList.remove("is-invalid");
    champCourriel.classList.remove("is-valid");
    champCourriel.classList.remove("is-invalid");
    champMessage.classList.remove("is-valid");
    champMessage.classList.remove("is-invalid");
}



/**
 * Affiche le copyright avec l'année courante
 */
function copyright() {
    let annee = new Date().getFullYear();
    document.getElementById("copyright").innerText = `\u00A9 ${annee} Héli-Express. Tous droits réservés.`;
}

/**
 * Gère les styles pour la rétraction du Nav
 */
function gererNav() {
    if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
        document.getElementById("navbar-retraction").classList.add("navbar-retraction");
    }
    else {
        document.getElementById("navbar-retraction").classList.remove("navbar-retraction");

    }
}

/**
 * S'occupe de l'initialisation de la page.
 */
function initialisation() {
    //Affiche le copyright avec l'année courante
    copyright();

    //Gère la rétraction du Nav au scroll
    window.addEventListener('scroll', gererNav, false);

    // Affecter les variables globales :
    champNom = document.getElementById("nom");
    divErreurNom = document.getElementById("formNom");
    champTel = document.getElementById("telephone");
    champCourriel = document.getElementById("courriel");
    champMessage = document.getElementById("message");
    divErreurMessage = document.getElementById("formMessage");

    // Associer les événements :
    champNom.addEventListener('blur', changementDansNom, false);
    champTel.addEventListener('blur', changementDansTel, false);
    champCourriel.addEventListener('blur', changementDansCourriel, false);
    champMessage.addEventListener('blur', changementDansMessage, false);

    document.getElementById("myForm").addEventListener('submit', gererSubmit, false);
    document.getElementById("myForm").addEventListener('reset', gererReset, false);
}

// Indique d'appeler la fonction initialisation() lorsque la page web est complètement chargée.
window.addEventListener("load", initialisation, false);